import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { AsyncValue, RootState } from "../..";
import {
  GetAssetIntervalAvailabilityByAssetIdsDto,
  GetAssetIntervalPerformanceByAssetIdsDto,
  GetAssetIntervalQualityByAssetIdsDto,
  GetUtilRawsGroupedByStatesByAssetIdQueryDto,
} from "../../../services/httpService";
import {
  getAssetsAvailabilityByAssetIds,
  getAssetsPerformanceByAssetIds,
  getAssetsQualityByAssetIds,
} from "../../../services/main/oeeService";
import { getUtilRawsGroupedByStatesByAssetId } from "../../../services/main/utilizationEventsService";
import { selectAvailableFlatAssets } from "../../user/assets-selection/assetsSelectionSlice";

export interface KpisShiftHistory {
  availability: Array<GetAssetIntervalAvailabilityByAssetIdsDto>;
  quality: Array<GetAssetIntervalQualityByAssetIdsDto>;
  performance: Array<GetAssetIntervalPerformanceByAssetIdsDto>;
}

export interface CurrentKpis {
  assetId: string | undefined;
  oEE: number | null;
  targetOEE: number | undefined;
  availability: number | null;
  targetAvailability: number | undefined;
  quality: number | null;
  targetQuality: number | undefined;
  performance: number | null;
  targetPerformance: number | undefined;
}

export interface AssetGroupedUtilStates {
  assetId: string;
  states: Array<GetUtilRawsGroupedByStatesByAssetIdQueryDto> | undefined;
}

export interface KpisState {
  shiftOee: AsyncValue<KpisShiftHistory>;
  // currentOee: AsyncValue<Array<CurrentKpis>>;
  utilStates: AsyncValue<Array<AssetGroupedUtilStates>>;
}

const initialState: KpisState = {
  shiftOee: {
    value: {
      availability: [],
      performance: [],
      quality: [],
    },
    pending: false,
  },
  // currentOee: {
  //   value: [],
  //   pending: false,
  // },
  utilStates: {
    value: [],
    pending: false,
  },
};

export const loadSelectedAssetsShiftKpisAsync = createAsyncThunk(
  "kpis/loadSelectedAssetsShiftKpisAsync",
  async (_, { getState }) => {
    const availableAssets = selectAvailableFlatAssets(getState() as RootState);
    const assetsIds = availableAssets.map((a) => a.id);
    const resultAvailability = await getAssetsAvailabilityByAssetIds(
      assetsIds,
      null,
      null
    );
    const resultPerformance = await getAssetsPerformanceByAssetIds(
      assetsIds,
      null,
      null
    );
    const resultQuality = await getAssetsQualityByAssetIds(
      assetsIds,
      null,
      null
    );
    const response = {
      availability: resultAvailability ?? [],
      quality: resultQuality ?? [],
      performance: resultPerformance ?? [],
    } as KpisShiftHistory;
    return response;
  }
);

// export const loadSelectedAssetsCurrentKpisAsync = createAsyncThunk(
//   "kpis/loadSelectedAssetsCurrentKpisAsync",
//   async (_, { getState }) => {
//     const availableAssets = selectAvailableFlatAssets(getState() as RootState);
//     const assetsIds = availableAssets.map((a) => a.id);
//     let response: Array<CurrentKpis> = [];
//     await Promise.all(
//       assetsIds.map(async (assetId) => {
//         const performanceResult = await getPerformanceByAssetId(
//           assetId,
//           undefined,
//           undefined
//         );
//         const qualityResult = await getQualityByAssetId(
//           assetId,
//           undefined,
//           undefined
//         );
//         const availabilityResult = await getAvailabilityByAssetId(
//           assetId,
//           undefined,
//           undefined
//         );

//         if (performanceResult && qualityResult && availabilityResult) {
//           let oee: number | null = null;
//           if (
//             availabilityResult.availability &&
//             qualityResult.quality &&
//             performanceResult.performance
//           ) {
//             oee =
//               (availabilityResult.availability *
//                 performanceResult.performance *
//                 qualityResult.quality) /
//               10000;
//           }
//           response = [
//             ...response,
//             {
//               assetId: assetId,
//               ...availabilityResult!,
//               ...performanceResult!,
//               ...qualityResult!,
//               oEE: oee,
//               targetOEE:
//                 (availabilityResult.targetAvailability +
//                   performanceResult.targetPerformance +
//                   qualityResult.targetQuality) /
//                 3,
//             },
//           ];
//         }
//       })
//     );
//     return response;
//   }
// );

interface LoadAssetUtilStatesParams {
  assetId: string;
}

export const loadAssetUtilStatesAsync = createAsyncThunk(
  "kpis/loadAssetUtilStatesAsync",
  async (params: LoadAssetUtilStatesParams, { getState }) => {
    const result = await getUtilRawsGroupedByStatesByAssetId(params.assetId);
    return { assetId: params.assetId, states: result };
  }
);

export const kpisSlice = createSlice({
  name: "kpis",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadSelectedAssetsShiftKpisAsync.pending, (state, action) => {
        state.shiftOee.pending = true;
      })
      .addCase(loadSelectedAssetsShiftKpisAsync.fulfilled, (state, action) => {
        state.shiftOee.value = action.payload!;
        state.shiftOee.pending = false;
      })
      .addCase(loadSelectedAssetsShiftKpisAsync.rejected, (state, action) => {
        state.shiftOee.value = {
          availability: [],
          performance: [],
          quality: [],
        };
        state.shiftOee.pending = false;
      })
      // .addCase(loadSelectedAssetsCurrentKpisAsync.pending, (state, action) => {
      //   state.currentOee.pending = true;
      // })
      // .addCase(
      //   loadSelectedAssetsCurrentKpisAsync.fulfilled,
      //   (state, action) => {
      //     state.currentOee.value = action.payload!;
      //     state.currentOee.pending = false;
      //   }
      // )
      // .addCase(loadSelectedAssetsCurrentKpisAsync.rejected, (state, action) => {
      //   state.currentOee.value = [];
      //   state.currentOee.pending = false;
      // })
      .addCase(loadAssetUtilStatesAsync.pending, (state, action) => {
        state.utilStates.pending = true;
      })
      .addCase(loadAssetUtilStatesAsync.fulfilled, (state, action) => {
        state.utilStates.value = state.utilStates.value.filter(
          (ul) => ul.assetId !== action.payload.assetId
        );
        state.utilStates.value.push(action.payload!);
        state.utilStates.pending = false;
      })
      .addCase(loadAssetUtilStatesAsync.rejected, (state, action) => {
        // state.utilStates.value = [];
        state.utilStates.pending = false;
      });
  },
});

// export const {} = kpisSlice.actions;

export const selectAssetsShiftKPIs = createSelector(
  (state: RootState) => state.main.kpis.shiftOee.value,
  (shiftKpis) => shiftKpis
);

export const selectAssetsShiftKPIsPending = createSelector(
  (state: RootState) => state.main.kpis.shiftOee.pending,
  (pending) => pending
);

// export const selectAssetsCurrentKPIs = createSelector(
//   (state: RootState) => state.main.kpis.currentOee.value,
//   (currentKpis) => currentKpis
// );

// export const selectAssetsCurrentKPIsPending = createSelector(
//   (state: RootState) => state.main.kpis.currentOee.pending,
//   (pending) => pending
// );

export const selectAssetsUtilStates = createSelector(
  (state: RootState) => state.main.kpis.utilStates.value,
  (utilStates) => utilStates
);

export const selectAssetsUtilStatesPending = createSelector(
  (state: RootState) => state.main.kpis.utilStates.pending,
  (pending) => pending
);

export const selectShiftKPIsByAssetId = createSelector(
  [selectAssetsShiftKPIs, (_, assetId: string) => assetId],
  (shiftKpis, assetId) => {
    return {
      availability: _.orderBy(
        shiftKpis.availability.filter((k) => k.assetId === assetId),
        "datetime",
        "asc"
      ),
      performance: _.orderBy(
        shiftKpis.performance.filter((k) => k.assetId === assetId),
        "datetime",
        "asc"
      ),
      quality: _.orderBy(
        shiftKpis.quality.filter((k) => k.assetId === assetId),
        "datetime",
        "asc"
      ),
    };
  }
);

export const selectCurrentKPIsfromIntervalsByAssetId = createSelector(
  [selectShiftKPIsByAssetId, (_, assetId: string) => assetId],
  (intervalKpis, assetId) => {
    let currentQuality = intervalKpis.quality.at(-1);
    let currentPerformance = intervalKpis.performance.at(-1);
    let currentAvailability = intervalKpis.availability.at(-1);
    let oee: number | null = null;
    let oeeTarget: number | null = null;

    if (
      currentQuality !== undefined &&
      currentPerformance !== undefined &&
      currentAvailability !== undefined
    ) {
      if (
        currentAvailability.availability != null &&
        currentPerformance.performance &&
        currentQuality.quality
      ) {
        oee =
          (currentQuality.quality *
            currentPerformance.performance *
            currentAvailability.availability) /
          10000;
      }

      if (
        currentAvailability.targetAvailability != null &&
        currentPerformance.targetPerformance &&
        currentQuality.targetQuality
      ) {
        oeeTarget =
          (currentQuality.targetQuality +
            currentPerformance.targetPerformance +
            currentAvailability.targetAvailability) /
          3;
      }
    }
    return {
      assetId: assetId,
      availability: currentAvailability?.availability ?? null,
      targetAvailability: currentAvailability?.targetAvailability,
      quality: currentQuality?.quality ?? null,
      targetQuality: currentQuality?.targetQuality,
      performance: currentPerformance?.performance ?? null,
      targetPerformance: currentPerformance?.targetPerformance,
      oEE: oee,
      targetOEE: oeeTarget,
    };
  }
);

// export const selectCurrentKPIsByAssetId = createSelector(
//   [selectAssetsCurrentKPIs, (_, assetId: string) => assetId],
//   (currentKpis, assetId) => {
//     return currentKpis.find((k) => k.assetId === assetId);
//   }
// );

export const selectUtilStatesByAssetId = createSelector(
  [selectAssetsUtilStates, (_, assetId: string) => assetId],
  (utilStates, assetId) => utilStates.find((u) => u.assetId === assetId)?.states
);

export default kpisSlice.reducer;
