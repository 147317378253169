import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../..";
import {
  NestedAsset,
  getNestedAssetNodeId,
  selectNestedAssets,
} from "../../definitions/assetsSlice";

export interface SelectedAsset {
  assetId: string;
  sourceStack: Array<string>;
}

export interface AvailableAsset {
  id: string;
  parentAssetId: string | null;
  name: string | null;
  description: string | null;
  path: string | null;
}

export const initialState: Array<SelectedAsset> = [];

export const assetsSelectionSlice = createSlice({
  name: "assetsNavigation",
  initialState,
  reducers: {
    assetsSelected: (state, action: PayloadAction<Array<SelectedAsset>>) => {
      return action.payload;
    },
  },
});

export const { assetsSelected } = assetsSelectionSlice.actions;

export const selectSelectedAssets = createSelector(
  (state: RootState) => state.user.selectedAssets,
  (selectedAssets) => selectedAssets
);

export const selectSelectedAssetsIds = createSelector(
  (state: RootState) => state.user.selectedAssets,
  (selectedAssets) => selectedAssets.map((a) => a.assetId)
);

export const selectSelectedAssetsIdsWithSourceStack = createSelector(
  (state: RootState) => state.user.selectedAssets,
  (selectedAssets) =>
    selectedAssets.map(
      (a) =>
        (a.sourceStack ? a.sourceStack.join("/") : "") +
        (a.sourceStack && a.sourceStack.length !== 0 ? "/" : "") +
        a.assetId
    )
);

export const selectSelectedAssetsSourceStacks = createSelector(
  (state: RootState) => state.user.selectedAssets,
  (selectedAssets) => selectedAssets.map((a) => a.sourceStack)
);

export const selectSelectedAssetPath = createSelector(
  selectSelectedAssets,
  selectNestedAssets,
  (selectedAssets, nestedAssets) => {
    if (selectedAssets.length === 1) {
      let sourceStack = selectedAssets[0].sourceStack;
      const assetId = selectedAssets[0].assetId;
      if (sourceStack && sourceStack.length > 0) {
        let currentAssets = nestedAssets;
        sourceStack.forEach((sId) => {
          const foundAsset = currentAssets.find((a) => a.id === sId);
          if (!foundAsset) return null;
          currentAssets = foundAsset.childrenAssets ?? [];
        });
        return currentAssets.find((a) => a.id === assetId)?.path;
      }
      return nestedAssets.find((a) => a.id === assetId)?.path;
    } else {
      return "";
    }
  }
);

// export const selectSelectedAssets = createSelector(
//   selectNestedAssets,
//   selectSelectedAssetsSourceStacks,
//   (nestedAssets, selectedAssetsSourceStacks) => {
//     if (selectedAssetsSourceStacks.length > 0) {
//       let currentAssets = nestedAssets;
//       selectedAssetsSourceStacks.forEach((sId) => {
//         const foundAsset = currentAssets.find((a) => a.id === sId);
//         if (!foundAsset) return null;
//         currentAssets = foundAsset.childrenAssets ?? [];
//       });
//       return currentAssets.find((a) => a.id === selectedAssetId);
//     } else if (selectedAssetId) {
//       return nestedAssets.find((a) => a.id === selectedAssetId);
//     } else {
//       return null;
//     }
//   }
// );

export const selectAllFlatAssets = createSelector(
  [selectNestedAssets],
  (nestedAssets) => {
    const nodeIds = nestedAssets.map((n) =>
      getNestedAssetNodeId(n.sourceStack, n.id)
    );
    const selectedAssets = nodeIds.map(
      (n) =>
        ({
          sourceStack: n.split("/").slice(0, -1),
          assetId: n.split("/").at(-1),
        } as SelectedAsset)
    );

    const lookForChildrenAssets = (
      assets: Array<AvailableAsset>,
      nestedAsset: NestedAsset
    ) => {
      nestedAsset?.childrenAssets?.forEach((a) => {
        if (a.childrenAssets === null || a.childrenAssets.length === 0) {
          assets.push({
            id: a.id,
            name: a.name,
            description: a.description,
            parentAssetId: a.parentAssetId,
            path: a.path,
          });
        } else if (Array.isArray(a.childrenAssets)) {
          lookForChildrenAssets(assets, a);
        }
      });
    };

    let availableFlatAssets: Array<AvailableAsset> = [];

    selectedAssets.forEach(({ assetId, sourceStack }) => {
      if (sourceStack && sourceStack.length > 0) {
        let currentAssets = nestedAssets;
        sourceStack.forEach((sId) => {
          const foundAsset = currentAssets.find((a) => a.id === sId);
          if (!foundAsset) return null;
          currentAssets = foundAsset.childrenAssets ?? [];
        });
        lookForChildrenAssets(
          availableFlatAssets,
          currentAssets.find((a) => a.id === assetId)!
        );
      } else if (assetId) {
        lookForChildrenAssets(
          availableFlatAssets,
          nestedAssets.find((a) => a.id === assetId)!
        );
      } else {
        return [];
      }
    });

    return _.uniqBy(availableFlatAssets, "id");
  }
);

export const selectAvailableFlatAssets = createSelector(
  [selectSelectedAssets, selectNestedAssets],
  (selectedAssets, nestedAssets) => {
    const lookForChildrenAssets = (
      assets: Array<AvailableAsset>,
      nestedAsset: NestedAsset
    ) => {
      nestedAsset?.childrenAssets?.forEach((a) => {
        if (a.childrenAssets === null || a.childrenAssets.length === 0) {
          assets.push({
            id: a.id,
            name: a.name,
            description: a.description,
            parentAssetId: a.parentAssetId,
            path: a.path,
          });
        } else if (Array.isArray(a.childrenAssets)) {
          lookForChildrenAssets(assets, a);
        }
      });
    };

    let availableFlatAssets: Array<AvailableAsset> = [];

    selectedAssets.forEach(({ assetId, sourceStack }) => {
      if (sourceStack && sourceStack.length > 0) {
        let currentAssets = nestedAssets;
        sourceStack.forEach((sId) => {
          const foundAsset = currentAssets.find((a) => a.id === sId);
          if (!foundAsset) return null;
          currentAssets = foundAsset.childrenAssets ?? [];
        });
        lookForChildrenAssets(
          availableFlatAssets,
          currentAssets.find((a) => a.id === assetId)!
        );
      } else if (assetId) {
        lookForChildrenAssets(
          availableFlatAssets,
          nestedAssets.find((a) => a.id === assetId)!
        );
      } else {
        return [];
      }
    });

    return _.uniqBy(availableFlatAssets, "id");
  }
);

export const selectChildrenFlatAssets = createSelector(
  [
    (state: RootState, selAssets: Array<SelectedAsset>) => selAssets,
    selectNestedAssets,
  ],
  (selectedAssets, nestedAssets) => {
    const lookForChildrenAssets = (
      assets: Array<AvailableAsset>,
      nestedAsset: NestedAsset
    ) => {
      nestedAsset?.childrenAssets?.forEach((a) => {
        if (a.childrenAssets === null || a.childrenAssets.length === 0) {
          assets.push({
            id: a.id,
            name: a.name,
            description: a.description,
            parentAssetId: a.parentAssetId,
            path: a.path,
          });
        } else if (Array.isArray(a.childrenAssets)) {
          lookForChildrenAssets(assets, a);
        }
      });
    };

    let availableFlatAssets: Array<AvailableAsset> = [];

    selectedAssets.forEach(({ assetId, sourceStack }) => {
      if (sourceStack && sourceStack.length > 0) {
        let currentAssets = nestedAssets;
        sourceStack.forEach((sId) => {
          const foundAsset = currentAssets.find((a) => a.id === sId);
          if (!foundAsset) return null;
          currentAssets = foundAsset.childrenAssets ?? [];
        });
        lookForChildrenAssets(
          availableFlatAssets,
          currentAssets.find((a) => a.id === assetId)!
        );
      } else if (assetId) {
        lookForChildrenAssets(
          availableFlatAssets,
          nestedAssets.find((a) => a.id === assetId)!
        );
      } else {
        return [];
      }
    });

    return _.uniqBy(availableFlatAssets, "id");
  }
);

export default assetsSelectionSlice.reducer;
