import { UserIcon } from "@improdis/core";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import "simplebar/dist/simplebar.min.css";
import { env } from "../../../env";
import {
  assetsNavigationDialogClosed,
  assetsNavigationDialogOpened,
  selectAssetNavigationDialogOpen,
} from "../../../store/common/dialogs/assetNavigationSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectSelectedAssetPath,
  selectSelectedAssetsIds,
} from "../../../store/user/assets-selection/assetsSelectionSlice";
import {
  selectIsAuthorized,
  selectUserDetails,
} from "../../../store/user/authSlice";
import AppBarSettingsContent from "./AppBarSettings";

const UserInfo = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  paddingRight: "0.5rem",
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: "3.125rem",
  maxHeight: "3.5rem",
  padding: "0.1rem 1rem",
}));

const AppBarContent = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  flexGrow: 1,
}));

const AppBarSection = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "1rem",
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  marginTop: "0.3375rem",
  marginBottom: "0.3375rem",
  padding: "0.1rem",
}));

const StyledMenuIcon = styled(MenuIcon)(({ theme }) => ({
  width: "2.25rem",
  height: "2.25rem",
  fill: theme.palette.primary.contrastText,
}));

const Title = styled("div")(({ theme }) => ({
  textAlign: "left",
}));

const AssetSection = styled("div")(() => ({
  display: "block",
  maxHeight: "3.25rem",
  wordBreak: "break-word",
  overflow: "hidden",
}));

const AppBarAsset = styled("div")(({ theme }) => ({
  display: "flex",
  height: "inherit",
  overflow: "hidden",
  alignItems: "center",
  "& > hr": {
    marginRight: "1rem",
  },
}));

const StyledUserIcon = styled(UserIcon)(({ theme }) => ({
  height: 25,
  width: 25,
  fill: theme.palette.primary.contrastText,
  paddingRight: "0.5rem",
  paddingLeft: "0.5rem",
}));

interface AppBarSettingsProps {}

const AreasAppBar: React.FC<AppBarSettingsProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedAssets = useAppSelector(selectSelectedAssetsIds);
  const selectedAssetPath = useAppSelector(selectSelectedAssetPath);
  const openAssetsNavigation = useAppSelector(selectAssetNavigationDialogOpen);
  const isAuthorized = useAppSelector(selectIsAuthorized);
  const userInfo = useAppSelector(selectUserDetails);
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledAppBar position="relative" elevation={2}>
      <AppBarContent>
        <AppBarSection>
          <MenuButton
            onClick={() =>
              openAssetsNavigation
                ? dispatch(assetsNavigationDialogClosed())
                : dispatch(assetsNavigationDialogOpened())
            }
            size="large"
          >
            <StyledMenuIcon />
          </MenuButton>
          <Title>
            <Typography variant="primaryLabel">
              {process.env.REACT_APP_WEBSITE_NAME}
            </Typography>
            <div style={{ whiteSpace: "nowrap" }}>
              <Typography variant="secondaryLabel">
                {env.REACT_APP_THEME_TYPE === "AVEVA" ? "AVEVA" : "ImProdis"}
              </Typography>
            </div>
          </Title>
          {selectedAssets.length === 0 && (
            <AppBarAsset>
              <Divider orientation="vertical" variant="fullWidth" flexItem />
              {t("No asset selected")}
            </AppBarAsset>
          )}
          {selectedAssets.length === 1 && (
            <AppBarAsset>
              <Divider orientation="vertical" variant="fullWidth" flexItem />
              <AssetSection>
                {!matchesMobile
                  ? selectedAssetPath
                  : selectedAssetPath?.split(" > ").at(-1)}{" "}
              </AssetSection>
            </AppBarAsset>
          )}
          {selectedAssets.length > 1 && (
            <AppBarAsset>
              <Divider orientation="vertical" variant="fullWidth" flexItem />
              {t("Multi-selection mode")}
            </AppBarAsset>
          )}
        </AppBarSection>
        <AppBarSection>
          {isAuthorized && !matchesMobile && (
            <UserInfo>
              <StyledUserIcon />
              <Typography variant="subtitle1">
                {userInfo.firstName === null || userInfo.lastName === null
                  ? userInfo.userName
                  : userInfo.firstName + " " + userInfo.lastName}
              </Typography>
            </UserInfo>
          )}
          <AppBarSettingsContent></AppBarSettingsContent>
        </AppBarSection>
      </AppBarContent>
    </StyledAppBar>
  );
};

export default AreasAppBar;
